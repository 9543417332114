var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.layoutTemplate, {
    tag: "component",
    staticClass: "h-100dvh over-flow-y-scroll",
    attrs: { "doc-type": _vm.docType },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }