<template>
  <div
    id="help"
    class="pt-56"
  >
    <!-- TOOLBAR -->
    <Toolbar>
      <v-btn
        slot="btn"
        icon
        color="success"
        to="/service"
      >
        <Icon
          data="@icon/bottomNav/service.svg"
          width="24px"
          height="24px"
          class="icon--text"
        />
      </v-btn>
    </Toolbar>

    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="pa-0"
        >
          <!-- TAB -->
          <v-tabs
            v-model="tab"
            grow
            background-color="bg"
            color="primary"
          >
            <v-tab
              v-for="item in document"
              :key="item._id"
              :class="[{ 'px-0' : lang === 'zh-CN'}]"
            >
              <span class="subtitle-2">{{ item.name }}</span>
            </v-tab>
          </v-tabs>
          <v-divider class="divider" />
        </v-col>

        <!-- content -->
        <v-col
          v-if="document.length > 0"
          cols="12"
          class="pt-0 px-4"
        >
          <div
            v-for="(doc, index) in activeContent.docs"
            :key="doc + index"
            class="doc mt-4"
          >
            <div class="doc__title primary--text d-flex">
              <p class="white--text question primary mb-0 text-center">
                <span>Q</span>
              </p>
              {{ doc.name }}
            </div>
            <div class="doc__content caption title--text mt-3 mb-6">
              <div
                v-for="(text, itemIndex) in doc.content.split('\r\n')"
                :key="text + itemIndex"
              >
                {{ text }}
              </div>
            </div>
            <v-divider class="divider" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpMixin from '../mixins/helpMixin'

export default {
  mixins: [helpMixin],
}
</script>
