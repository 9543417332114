<template>
  <div
    id="help"
    class="pt-56"
  >
    <!-- TOOLBAR -->
    <ToolbarTemplate3>
      <v-btn
        slot="btn"
        icon
        color="success"
        to="/service"
      >
        <Icon
          data="@icon/bottomNav/service.svg"
          width="24px"
          height="24px"
          class="white--text"
        />
      </v-btn>
    </ToolbarTemplate3>

    <v-container>
      <!-- template3 -->
      <v-row>
        <v-col
          v-if="document"
          cols="12"
        >
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item, index) in document"
              :key="index"
              class="mb-2 rounded-lg card1"
            >
              <v-expansion-panel-header class="font-weight-bold">
                {{ item.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="doc in item.docs"
                  :key="doc._id"
                  class="subtitle-2"
                >
                  <!-- {{ doc.name }} -->
                  <p class="caption">
                    {{ doc.content }}
                  </p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpMixin from '../mixins/helpMixin'

export default {
  mixins: [helpMixin],
}
</script>

<style scoped>

.v-expansion-panel::after {
  display: none;
}
</style>
