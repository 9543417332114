import { mapActions, mapGetters, mapState } from 'vuex'
import Toolbar from '@/components/base/template/ToolbarTemplate1.vue'
import ToolbarTemplate3 from '@/components/base/template/ToolbarTemplate3.vue'

export default {
  components: {
    Toolbar,
    ToolbarTemplate3,
  },

  data: () => ({
    docType: 'faq',
    docs: [],
  }),

  computed: {
    ...mapState({
      serviceUrl: state => state.serviceUrl,
    }),

    ...mapGetters(['theme', 'layout']),
  },

  mounted() {
    this.set_toolbar_title(this.$t('myCenter.commonProblem'))
    this.getProblemDoc(this.docType)
    this.getServiceUrl()
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_type_doc',
      'get_service_url',
      'show_alert',
    ]),

    getProblemDocSuccess({ docs }) {
      this.docs = docs
    },

    async getProblemDoc(type) {
      const { docs } = await this.get_type_doc(type)
      if (docs) this.getProblemDocSuccess(docs[0])
    },

    /**
     * 取得客服連結 (失敗)
     * @date 2021-08-17
     */
    getServiceUrlFail() {
      this.show_alert({ icon: 'fail' })
    },

    /**
     * 取得客服連結
     * @date 2021-08-17
     */
    getServiceUrl() {
      this.get_service_url()
    },

    /**
     * 另開客服連結頁面
     * @date 2021-08-17
     */
    toServicePage() {
      if (!this.serviceUrl) return this.getServiceUrlFail()
      window.open(this.serviceUrl, '_blank')
    },
  },
}
