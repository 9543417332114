import Toolbar from '@/components/base/template/ToolbarTemplate1.vue'
import ToolbarTemplate3 from '@/components/base/template/ToolbarTemplate3.vue'

import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Toolbar,
    ToolbarTemplate3,
  },

  props: {
    docType: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    tab: 0,

    document: [],
  }),

  computed: {
    ...mapGetters(['lang']),

    activeContent() {
      return this.document[this.tab]
    },

    preType() {
      const typeForHelp = this.docType === 'help'

      return {
        title: typeForHelp
          ? this.$t('footer.新手幫助')
          : this.$t('myCenter.responsibility'), // 企業事務

        type: typeForHelp ? 'help' : 'business',
      }
    },
  },

  mounted() {
    this.getHelpDocument(this.preType.type)
    this.set_toolbar_title(this.preType.title)
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_type_doc',
    ]),

    getHelpDocumentSuccess(data) {
      this.document = data?.docs
    },

    async getHelpDocument(type) {
      const { docs } = await this.get_type_doc(type)
      if (docs) this.getHelpDocumentSuccess(docs[0])
    },
  },
}
