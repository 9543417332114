<template>
  <div
    id="problem"
    class="pt-56"
  >
    <!-- TOOLBAR -->
    <ToolbarTemplate3>
      <v-btn
        slot="btn"
        icon
        color="success"
        to="/service"
      >
        <Icon
          data="@icon/bottomNav/service.svg"
          width="24px"
          height="24px"
          class="white--text"
        />
      </v-btn>
    </ToolbarTemplate3>
    <!-- content -->
    <v-container>
      <v-row class="px-1">
        <v-col
          cols="12"
          class="pt-0"
        >
          <div
            v-for="(doc, index) in docs"
            :key="doc + index"
            class="doc mt-4"
          >
            <div
              class="doc__title d-flex"
              :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
            >
              {{ index + 1 }}. {{ doc.name }}
            </div>
            <div class="doc__content caption text--text mt-3 mb-6">
              <div
                v-for="(text, itemIndex) in doc.content.split('\r\n')"
                :key="text + itemIndex"
              >
                {{ text }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import problemMixin from '../mixins/problemMixin'

export default {
  mixins: [problemMixin],
}
</script>
