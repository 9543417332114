var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-56", attrs: { id: "help" } },
    [
      _c(
        "ToolbarTemplate3",
        [
          _c(
            "v-btn",
            {
              attrs: {
                slot: "btn",
                icon: "",
                color: "success",
                to: "/service",
              },
              slot: "btn",
            },
            [
              _c("Icon", {
                staticClass: "white--text",
                attrs: {
                  data: require("@icon/bottomNav/service.svg"),
                  width: "24px",
                  height: "24px",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _vm.document
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-expansion-panels",
                        _vm._l(_vm.document, function (item, index) {
                          return _c(
                            "v-expansion-panel",
                            {
                              key: index,
                              staticClass: "mb-2 rounded-lg card1",
                            },
                            [
                              _c(
                                "v-expansion-panel-header",
                                { staticClass: "font-weight-bold" },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c(
                                "v-expansion-panel-content",
                                _vm._l(item.docs, function (doc) {
                                  return _c(
                                    "div",
                                    { key: doc._id, staticClass: "subtitle-2" },
                                    [
                                      _c("p", { staticClass: "caption" }, [
                                        _vm._v(" " + _vm._s(doc.content) + " "),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }